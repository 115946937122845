import React from 'react';

import { Avatar, Grid } from '@mui/material';
import { SocialIcon } from 'react-social-icons';

import './styles.scss';

const containerClasses = { root: 'avatar' };
const avatarClasses = { circular: 'avatar__image' };
const avatarContainerClasses = { root: 'avatar__container' };

const CustomAvatar: React.FC = () => (
  <Grid classes={containerClasses} item>
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      columns={12}
    >
      <Grid
        classes={avatarContainerClasses}
        item
        lg={12}
        md={4}
        sm={4}
      >
        <Avatar
          sx={{ width: 150, height: 150 }}
          src="/assets/images/avatar.jpg"
          classes={avatarClasses}
          variant="circular"
        />
        <h2 className="avatar__name">Hikari Sensei</h2>
      </Grid>
      <Grid item lg={12} md={4} sm={4}>
        <SocialIcon
          bgColor="#ffffff"
          fgColor="#111111"
          className="avatar__social-networks"
          url="https://www.tiktok.com/@hikari_luz_guadalupe"
        />
        <SocialIcon
          bgColor="#ffffff"
          fgColor="#ff0000"
          className="avatar__social-networks"
          url="https://www.youtube.com/channel/UCYi6ZafdM4EBSAaKD2CaliQ"
        />
        <SocialIcon
          bgColor="#ffffff"
          fgColor="#ff597a"
          className="avatar__social-networks"
          url="https://www.instagram.com/hikari__luz/"
        />
        <SocialIcon
          bgColor="#ffffff"
          fgColor="#4267B2"
          className="avatar__social-networks"
          url="https://www.facebook.com/hikari.luz.guadalupe"
        />
      </Grid>
    </Grid>
  </Grid>
);

export default CustomAvatar;

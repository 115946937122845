import React, { Suspense } from 'react';

import { Route, Switch } from 'react-router-dom';

import About from 'views/about';
import Home from 'views/home';
import Lessons from 'views/lessons';
import CookingClass from 'views/cooking-class';

import './app.scss';

const App: React.FC = () => {
  return (
    <Suspense fallback="loading">
      <Switch>
        <Route path="/acerca" exact component={About} />
        <Route path="/clases-de-japones" exact component={Lessons} />
        <Route
          path="/clases-de-cocina"
          exact
          component={CookingClass}
        />
        <Route path="/" exact component={Home} />
      </Switch>
    </Suspense>
  );
};

export default App;

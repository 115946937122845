import React, { useState } from 'react';

import { Button, Grid, TextField } from '@mui/material';

import './style.scss';

type FormData = {
  comment: string;
  email: string;
  inquirySource: string;
  middleNames: string;
  name: string;
  phone: string;
};

type ContactFormProps = {
  inquirySource: string;
  onFormSend: (formData: FormData) => void;
};

const ContactForm: React.FC<ContactFormProps> = ({
  inquirySource,
  onFormSend,
}) => {
  const [name, setName] = useState<string>('');
  const [middleNames, setMiddleNames] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [comment, setComment] = useState<string>('');

  const handleNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => setName(value);
  const handleMiddleNamesChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => setMiddleNames(value);
  const handleEmailChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => setEmail(value);
  const handlePhoneChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => setPhone(value);
  const handleCommentChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => setComment(value);

  const clearForm = () => {
    setName('');
    setMiddleNames('');
    setEmail('');
    setPhone('');
    setComment('');
  };

  const onSend = () => {
    onFormSend({
      comment,
      email,
      inquirySource,
      middleNames,
      name,
      phone,
    });

    clearForm();
  };

  return (
    <Grid
      classes={{ root: 'contact-form' }}
      item
      sm={12}
      md={12}
      lg={12}
    >
      <Grid container spacing={2} direction="column">
        <Grid item>
          <h3 className="contact-form__form-title">¡Contáctame!</h3>
        </Grid>
        <Grid item>
          <TextField
            classes={{ root: 'contact-form__text-input' }}
            required
            label="Nombre"
            variant="standard"
            onChange={handleNameChange}
            value={name}
          />
          <TextField
            classes={{ root: 'contact-form__text-input' }}
            required
            label="Apellidos"
            variant="standard"
            onChange={handleMiddleNamesChange}
            value={middleNames}
          />
        </Grid>
        <Grid item>
          <TextField
            classes={{ root: 'contact-form__text-input' }}
            required
            label="Correo Electrónico"
            variant="standard"
            onChange={handleEmailChange}
            value={email}
          />
          <TextField
            classes={{ root: 'contact-form__text-input' }}
            required
            label="Teléfono de Contacto"
            variant="standard"
            onChange={handlePhoneChange}
            value={phone}
          />
        </Grid>
        <Grid item>
          <TextField
            classes={{ root: 'contact-form__text-input--text-field' }}
            required
            label="Comentario"
            variant="standard"
            multiline
            fullWidth
            onChange={handleCommentChange}
            value={comment}
          />
        </Grid>
        <Grid item textAlign="right">
          <Button
            onClick={onSend}
            color="primary"
            variant="contained"
          >
            Enviar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactForm;

import React from 'react';

import { Card, CardContent, Grid } from '@mui/material';

import './styles.scss';

const About: React.FC = () => (
  <Grid
    container
    spacing={2}
    direction="column"
    alignContent="center"
    alignItems="center"
    justifyContent="center"
    classes={{ root: 'about' }}
  >
    <Grid item sm={4} md={4} lg={4}>
      <Card>
        <CardContent>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <h2 className="about__self-introduction-title">
                Acerca de Hikari Sensei
              </h2>
              <img
                alt="Hikari Sensei"
                src="/assets/images/about-me.jpg"
                width={350}
              />
            </Grid>
            <Grid item>
              <p className="about__self-introduction-text">
                ¡Hola! Mi nombre es Hikari y vengo de Japón.
                <br />
                Llévo viviendo un desde 2019 en México. Me gusta
                <br />
                la Cultura Mexicana y sobretodo los tacos.
                <br />
                <br />
                Mis tacos favoritos son los de tripa bien dorada,
                <br />
                y mi bebida mexicana favorita es el tepache.
                <br />
                Mi ciudad favorita de México es Oaxaca. Es
                <br />
                un lugar que me da mucha energía y es muy bonito.
              </p>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

export default About;

import React, { useState } from 'react';

import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';
import ImageGallery from 'react-image-gallery';

import ContactForm from 'components/contact-form';

import './styles.scss';

const LessonsInfoImages = [
  {
    original: '/assets/images/clases-de-japones.jpg',
  },
  {
    original: '/assets/images/grupal-en-linea.jpg',
  },
  {
    original: '/assets/images/grupal-presencial.jpg',
  },
  {
    original: '/assets/images/grupo-conversacion.jpg',
  },
  {
    original: '/assets/images/clases-costos.jpg',
  },
];

const CookingClass: React.FC = () => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const toggleDialog = () => {
    setShowDialog((previousState) => !previousState);
  };

  const onFormSend = () => {
    toggleDialog();
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      classes={{ root: 'lessons' }}
    >
      <Grid item sm={4} md={4} lg={4}>
        <Card>
          <CardContent>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <ImageGallery
                  items={LessonsInfoImages}
                  showThumbnails={false}
                  lazyLoad
                />
              </Grid>
              <Grid item>
                <ContactForm
                  inquirySource="japanese-lessons"
                  onFormSend={onFormSend}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Dialog open={showDialog} onClose={toggleDialog} fullWidth>
        <DialogContent classes={{ root: 'lessons__dialog-content' }}>
          <img src="/assets/images/thankyou.gif" alt="Gracias!" />
          <p className="lessons__dialog-text">
            ¡Gracias por enviar tu información!
          </p>
          <p className="lessons__dialog-text">
            Pronto me contactaré contigo.
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={toggleDialog}
            color="primary"
            variant="contained"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default CookingClass;

import React, { useState } from 'react';

import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';

import ContactForm from 'components/contact-form';

import './styles.scss';

const CookingClass: React.FC = () => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const toggleDialog = () => {
    setShowDialog((previousState) => !previousState);
  };

  const onFormSend = () => {
    toggleDialog();
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      classes={{ root: 'cooking-classes' }}
    >
      <Grid item sm={4} md={4} lg={4}>
        <Card>
          <CardContent>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <CardMedia
                  classes={{ img: 'cooking-classes__image' }}
                  component="img"
                  src="/assets/images/clases-de-cocina.jpg"
                />
              </Grid>
              <Grid item>
                <ContactForm
                  inquirySource="cooking-class"
                  onFormSend={onFormSend}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Dialog open={showDialog} onClose={toggleDialog} fullWidth>
        <DialogContent>
          <p>Correo enviado =D</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={toggleDialog}
            color="primary"
            variant="contained"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default CookingClass;

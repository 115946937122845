import React from 'react';

import { Button, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

import Avatar from 'components/avatar';

import './styles.scss';

const Home: React.FC = () => {
  const history = useHistory();

  return (
    <Grid
      classes={{ container: 'home' }}
      container
      spacing={2}
      direction="column"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
    >
      <Avatar />
      <Grid item container direction="column" alignContent="center">
        <Button
          classes={{ outlined: 'home__button' }}
          variant="outlined"
          onClick={() => history.push('/acerca')}
        >
          Acerca de mi
        </Button>
        <Button
          classes={{ outlined: 'home__button' }}
          variant="outlined"
          onClick={() => history.push('/clases-de-japones')}
        >
          Clases de Japonés
        </Button>
        <Button
          classes={{ outlined: 'home__button' }}
          variant="outlined"
          onClick={() => history.push('/clases-de-cocina')}
        >
          Clases de Cocina
        </Button>
      </Grid>
    </Grid>
  );
};

export default Home;
